import React, { Component } from 'react';
import {HashLink as Link} from 'react-router-hash-link';
import './index.css';

import CinderLogo from '../../resources/cinderblock.png';
import IronLogo from '../../resources/favicon iron.png';
import WoodLogo from '../../resources/favicon wood.png';
import TruckLogo from '../../resources/favicon_truck.png';
import ContLogo from  '../../resources/favicon_hardhat.png';

// Component for Services page
export default class Services extends Component{

  constructor(props){
    super(props);
    this.state={

        }
      }
  componentDidMount(){
      window.scrollTo(0, 0)
      }

render() {

  const Servbutton = {
     backgroundColor: 'red',
    padding: '10px',
    color: 'white',
    borderRadius: '20px',
    textDecoration: 'none',
    fontFamily: 'rubikregular',
    fontWeight: 'bold'
   }

  const titleMain = {
    color:'#20375b'
  }

  const {services} = this.props;

  return (
    <div className="Services">

      <div className="paraInner" id="bigImage3"></div>

      <div id="servOptions">
         <h1 style={titleMain}>{services.title}</h1>

         <p id="servDesc">{services.title_p}</p>

        <div className="servBlok" id="cinderBlok">
            <div>
              <h1>{services.service1.title}</h1>
              <div className="servParaImg" id="servParaInner1">
                <img src={CinderLogo} alt="Blocs de béton" className="servicon"/>
                <p className="servtitle">{services.service1.title}</p>
              </div>

              <div className="blurbBlok">
                <p>{services.service1.about}</p>
                <ul>
                  <li>{services.service1.ten}</li>
                  <li>{services.service1.twelve}</li>
                  <li>{services.service1.twenty}</li>
                  <li>{services.service1.thrity}</li>
                </ul>
              </div>
            </div>
          <div>
              <h1>{services.service2.title}</h1>
              <div className="servParaImg" id="servParaInner2">

                <img src={IronLogo} alt="Barres de Fer" className="servicon"/>
                <p className="servtitle">{services.service2.title}</p>

              </div>

              <div className="blurbBlok">
                <p>{services.service2.about}</p>
                <ul>
                  <li>{services.service2.half}</li>
                  <li>{services.service2.quarter}</li>
                  <li>{services.service2.eight}</li>
                </ul>
              </div>
          </div>
          <div>
              <h1>{services.service3.title}</h1>
              <div className="servParaImg" id="servParaInner3">
                <img src={TruckLogo} alt="PIERRE CONCASSÉE" className="servicon"/>
                <p className="servtitle">{services.service3.title}</p>

              </div>

              <div className="blurbBlok">
                <p>{services.service3.about}</p>
              </div>
          </div>
          <div>
              <h1>{services.service4.title}</h1>
              <div className="servParaImg" id="servParaInner4">

                <img src={WoodLogo} alt="Bois" className="servicon"/>
                <p className="servtitle">{services.service4.title}</p>

              </div>

              <div className="blurbBlok">
                <p>{services.service4.about}</p>
              </div>
          </div>
            <Link to="/Contact#top" style={Servbutton}>{services.servicemat}</Link>
        </div>


        <div className="servBlok" id="contractBlok">
          <div>
            <h1>{services.service5.title}</h1>
            <div className="servParaImg" id="servParaInner5">

              <img src={ContLogo} alt="Références des Contracteur" className="servicon"/>
              <p className="servtitle">{services.service5.title}</p>
            </div>


            <div className="blurbBlok">
                <p>{services.service5.about}</p>
            </div>
          </div>

          <div>
              <h1>{services.service5.materialtitle}</h1>
              <div className="servParaImg" id="servParaInner6">
                <img src={ContLogo} alt="Transport de matériaux" className="servicon"/>
                <p className="servtitle">{services.service5.materialtitle}</p>
              </div>


              <div className="blurbBlok">
                  <p><span>{services.service5.materialbody}</span></p>
              </div>
          </div>

            <div>
              <h1>{services.service5.windowtitle}</h1>
              <div id="servParaInner7"></div>


              <div className="blurbBlok">
                  <p><span>{services.service5.windowbody}</span></p>
              </div>
            </div>

          <Link to="/Contact#top">{services.servicecont}</Link>
        </div>
        <div className="spacer"></div>
      </div>
    </div>
    )
  }
}
