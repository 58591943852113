import React from 'react';

// Component for Services page
export default function Footer(props){

 const bgSwitchfr = props.currentlang === 'french' ?
  {
    backgroundColor: '#D21F34'
  } : {
    border:  'solid #D21F34'
  }

  const bgSwitchen = props.currentlang === 'english' ?
   {
     backgroundColor: '#D21F34'
   } : {
     border:  'solid #D21F34'
   }

  return (
    <div className="Footer">
      {/*<p>Change Language/Changer en français</p>*/}
      <div className="footerswitch" style={bgSwitchfr}  onClick={()=>props.changeLanguage('french')}>Changer en français</div>
      <br />
      <div className="footerswitch" style={bgSwitchen}  onClick={()=>props.changeLanguage('english')}>Change to english</div>
    </div>
    )
  }
