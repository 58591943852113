import React, { Component } from 'react';
import './index.css';
import MediaQueries from 'react-responsive';
// import Logo from '../../resources/VFDlogo_favicon.png';
import Founders from '../../resources/VFDfounders.jpg';
import Truck from '../../resources/VFDworksite-fordtruckopt.jpg'
import Trucksm from '../../resources/VFDworksite-fordtruck-sm.jpg'
import Insta from '../../resources/insta.png';
// Component for about page
export default class About extends Component{
  constructor(props){
    super(props);
    this.state={

        }
      }

  componentDidMount(){
      window.scrollTo(0, 0)
  }

render(){
    const overFlow = {
      justifyContent: 'center',
      maxWidth: '750px',
      height: 'auto',
      marginTop: '1%'
    }

    const {about, contactinfo, store_hours} = this.props;

  return (
    <div className="about" id="top">
      <MediaQueries maxWidth ={768}>
        {(matches) => {
          if (matches){
            return <img src={Trucksm} alt="camion et bloc de béton"/>
          }else{
             return <img src={Truck} alt="camion et bloc de béton" style={overFlow}/>
          }
        }}

      </MediaQueries>
      <h1 style={{color: 'black'}}>{about.title}</h1>
      <h2>{about.subtitle}</h2>



<MediaQueries maxWidth ={768}>
{(matches) => {
  if (matches){
  return (

    <article className="aboutBlok-sm">
      <img src={Founders} alt="Les fondateurs de VFD" className="founderfloat" />
      {about.p1}
    </article>
  )
} else {
    return (
      <div className = "aboutBlok">

        <article>{about.p1}</article>
        <img src={Founders} alt="Les fondateurs de VFD" className="founderfloat" />
      </div>
    )
  }
}}
</MediaQueries>


<article className = "legacy">
{about.p2}
</article>

  <h2 className="tagline">"Vibro Fabrique Desir Staff will  welcome you with respect as always been" </h2>
    <div className="spacer"></div>

    <div className="contactAbout">
        <div className="contactUs">
          <h1>{contactinfo.title}</h1>
        </div>


      <h2><span>{contactinfo.question}</span></h2>

      <h1>{contactinfo.info.address}</h1>
      <h1>{contactinfo.info.borough}, {contactinfo.info.state}</h1>
      <h2>{contactinfo.info.phone.title}</h2>
      <h1><span>{contactinfo.info.phone.number}</span></h1>
      <h2> {contactinfo.info.secondphone.title} </h2>
      <h1> <span>{contactinfo.info.secondphone.number}</span></h1>
      <h2>{contactinfo.info.hours.title}</h2>
      <h1><span>{store_hours.mondays} {contactinfo.info.hours.time}</span></h1>
      <h1><span>{store_hours.tuesdays} {contactinfo.info.hours.time}</span></h1>
      <h1><span>{store_hours.odins_day} {contactinfo.info.hours.time}</span></h1>
      <h1><span>{store_hours.thors_day} {contactinfo.info.hours.time}</span></h1>
      <h1><span>{store_hours.fry_day} {contactinfo.info.hours.time}</span></h1>
      <h1><span>{store_hours.sat_day} {contactinfo.info.hours.sat_time}</span></h1>
      <h1><span>{store_hours.holi_hrs}</span></h1>


      <span> <h1>{contactinfo.info.instagram}</h1>  <a href="https://www.instagram.com/vibro_fabrique_s.a/"> <img src={Insta} alt="Logo instagram" id='insta' /> </a> </span>
      <h2>E-MAIL:</h2>
      <h1><span>{contactinfo.info.email}</span></h1>

      </div>

        <div className="contactSpacer">
        </div>

    </div>

      )
    }
  }
