import React from 'react';
import './index.css';
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/en-ca';

// finish a working test that switches between english and french loads from app.js
export default function PanelCard(props){

//make a function to change language for moment
(props.language === 'english')? moment.locale('en-ca') :moment.locale('fr');

return(

  <div className='contactBox'>
    <p> {(props.language === 'english')? props.engAdminCont.uploaded :props.frAdminCont.uploaded} {moment(props.obj.timestamp).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
    <div className='contactdiv'>
  <h2><b>{(props.language === 'english')? props.engAdminCont.name :props.frAdminCont.name}</b> {props.obj.form.name} </h2>
  <h2><b>Email: </b> {props.obj.form.email}</h2>
  </div>
  <hr/>
  <p> <b>{(props.language === 'english')? props.engAdminCont.country :props.frAdminCont.country}</b> {props.obj.nationName}  </p>
  <p><b>{(props.language === 'english')? props.engAdminCont.countryCode :props.frAdminCont.countryCode} </b><span className='phnsty'> {` +${props.obj.nationCode}`} </span></p>
  <p><b>Phone:</b> <span className='phnsty'>{` ${props.obj.form.phone}`} </span></p>

  <span className='consp'><p className='ups'>Message: </p> <pre><p className='msg'>
  {props.obj.form.message}
  </p> </pre></span>
  <div className='butrow'><button onClick={() => props.deleteContact(props.obj.key)}>{(props.language === 'english')? props.engAdminCont.delete :props.frAdminCont.delete} </button> </div>
  </div>


  )


}
