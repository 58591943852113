import React, { Component } from 'react';
import './index.css';
import firebase from '../../firebase';
import Industry from '../../resources/VFDlogo_favicon.png';

export default class AdminLogin extends Component {

  constructor(props){
    super(props);
    this.state={
      email:"",
      password:"",
      errors:[],
        }
        //  gets the does the sign in and holds the input
        this.fireSignin = this.fireSignin.bind(this);
        // gets the token for the user for different auth levels
        this.fireToken = this.fireToken.bind(this);
        // write to get the state changed
        this.fireWrite =  this.fireWrite.bind(this);
      }


    handleChange = event => {
      this.setState({ [event.target.name]:event.target.value.substring(0, 80) });
  };


    displayErrors = errors => errors.map((error, i) => <p key={i}>{error.message}</p>);

    isFormValid = ({email, password}) =>
    email && password;


    fireSignin = async (email, pass) => {
      try{
    const signedInUser = await firebase.auth().signInWithEmailAndPassword(email, pass)
      console.log('got the user! ',signedInUser)
            //setting the token result inside of a promise
            return signedInUser;


    }catch(err){
      console.error(err);
      this.setState({errors:this.state.errors.concat(err)});
    }// end of catch

    //setting the token result inside of a promise
    // console.log('got the user! ',signedInUser)
    // return signedInUser;
    }


    fireToken =  async(signedInUser) => {
      // debugger;
      try {
        await firebase.auth().onAuthStateChanged(async user =>{
          // if a user is detected get the token result
          if(user != null){
          let idTokenResult = null;

         idTokenResult = await user.getIdTokenResult();
         // then  set the creds
         console.log('we recieve from the token: ', idTokenResult.claims);

         await this.props.setCreds(idTokenResult.claims.admin, idTokenResult.claims.rank);
         // then pass the user object to be rendered
         console.log('firetoken is returning: ',signedInUser)

         await this.fireWrite(signedInUser,idTokenResult);

         return signedInUser;

       }
       else{
         console.log('no Token recieved');
         this.props.setCreds(null);
       }
     });

        }catch(err){
          console.error(err);
          this.setState({errors:this.state.errors.concat(err)});
        }// end of catch

          // passing on the token
    }


    fireWrite = async(signedInUser, idTokenResult) =>{
      console.log('finally we get')
      // debugger;
            await this.props.setCreds(idTokenResult.claims.admin, idTokenResult.claims.rank);
            await localStorage.setItem('user', signedInUser.user);
            await this.props.setUser(signedInUser.user);
            await this.props.setAuth(firebase.auth().currentUser.I);
            return signedInUser;
    }



    handleSubmit = async (event)  => {
        event.preventDefault();
        // debugger;
        try {
        if (this.isFormValid(this.state)){
          this.setState({ errors:[]})
          const authChange = await this.fireSignin(this.state.email, this.state.password);
          await this.fireToken(await authChange);
          // const writeResult = await this.fireWrite( await tokenResult);

        }// end of if isFormValid

      }// end of try
        catch(err){
          console.error(err);
          this.setState({errors:this.state.errors.concat(err)});
        }// end of catch
      }



    handleInputError = (errors, inputName) => {
        return errors.some(error=> error.message.toLowerCase().includes(inputName)) ? "error": "";
      }

  componentDidMount(){
      window.scrollTo(0, 0)
  }


 render() {

   return(
     <div className='admin'>
     <img src={Industry} alt="logo d'entreprise" className="admin logo" />

      <h1>{this.props.adminlogin.Employeenot}</h1>
      {this.displayErrors(this.state.errors)}
      <div className='login'>
        <form className='loginbox'  onSubmit={this.handleSubmit} autoComplete="on" >
          <label><span style={{color:'rgb(210, 31, 52)'}}>*</span>Email:
          <input type="email"
            required
            name="email"
            placeholder="email@email.com"
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            onChange={this.handleChange} value={this.state.email}
            autoComplete="email" />
          </label>

          <label> <span style={{color:'rgb(210, 31, 52)'}}>*</span>{this.props.adminlogin.passnplace}:
          <input type="password" name="password"
            required
            placeholder={this.props.adminlogin.passnplace}
            onChange={this.handleChange}
            value={this.state.password}
            autoComplete="current-password"
            />
          </label>

          <input type="submit" value={this.props.adminlogin.submit} />
          <label style={{color:'rgb(210, 31, 52)','textAlign': 'right' }}>* {this.props.adminlogin.required}</label>
          </form>
      </div>
        </div>
   )
 }
}
