import React from 'react';
import './index.css';
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/en-ca';

// finish a working test that switches between english and french loads from app.js
export default function AdminPanelCard(props){

// will show the english names for the permission levels otherwise show the french name for it
  function rankControl(eng, rank){
    if(eng === 'english'){
      return( rank )
    }else if(eng !== 'english' && rank === 'owner'){
      return( 'propriétaire' )
    }else if(eng !== 'english' && rank === 'supervisor'){
      return( 'superviseur' )
    }else if(eng !== 'english' && rank === 'worker'){
      return( 'travailleur' )
    }
  }

(props.language === 'english')? moment.locale('en-ca') :moment.locale('fr');

return(
  <div className='contactBox'>
    <p>{(props.language === 'english')? props.engAdmin.uploaded :props.frAdmin.uploaded} {moment(props.obj.timestamp).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
    <div className='contactdiv'>
  <h2><b>{(props.language === 'english')? props.engAdmin.name :props.frAdmin.name} </b> {props.obj.name} </h2>
  <h2><b>Email: </b> {props.obj.email}</h2>
  <h2><b>{(props.language === 'english')? props.engAdmin.rank :props.frAdmin.rank} </b> {rankControl(props.language,props.obj.rank)}</h2>
  </div>
  <hr/>
    <div className='butrow'>
      {(props.obj.rank === 'owner') ? <></> :
        <>
        <button onClick={() => props.confirmState(props.obj.key,props.obj.email,'delete')}>{(props.language === 'english')? props.engAdmin.delete :props.frAdmin.delete}</button>
        <button onClick={() => props.confirmState(props.obj.key,props.obj.email,'upgrade')}>{(props.language === 'english')? props.engAdmin.raise :props.frAdmin.raise}</button>
        <button onClick={() => props.confirmState(props.obj.key,props.obj.email,'downgrade')}>{(props.language === 'english')? props.engAdmin.lower :props.frAdmin.lower}</button>
      </>}
    </div>
  </div>
  )
}
