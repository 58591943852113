import React, {Component} from 'react';
import './index.css';
import MediaQueries from 'react-responsive';
import Shed from '../../resources/VFDworksite-outside_better.jpg';
import MobileShed from '../../resources/vfdsitemobile.jpg';
import Insta from '../../resources/insta.png';
import ContentModal from '../contactModal';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import firebase from '../../firebase';

// Component for contact page
export default class Contact extends Component{
  constructor(props){
    super(props);
      this.state ={
        contact: {
          name:'',
          phone: '',
          email: '',
          message: ''
        },
        show:false,
        success:true,
        modalAnim: {animation: 'modalFade .3s ease-in'} ,
        modalBackground: {animation: 'modalBack .3s ease-in'} ,
        phoneValid:false,
        formBackground:{},
        nationName:'Haiti',
        nationCode:'509',
        defaultNationCode:'ht',
        contactRef: firebase.database().ref('contact')
      }
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.showModal = this.showModal.bind(this);
      this.hideModal = this.hideModal.bind(this);
      this.handlePhone = this.handlePhone.bind(this);
      this.handleFlag = this.handleFlag.bind(this);
  }

  //hangle change evaluates the inputs and will limit the input length of name and email to 80 characters, the message will be limited to ten thousand characters
  handleChange = (e) => {
  const{ name, value } = e.target;

  if(name === 'name'|| name === 'email' ) {
  this.setState(prevState => ({
    contact: {
      ...prevState.contact,
      [name]: value.substring(0, 200)
    }
  }));
}

  else{
    this.setState(prevState => ({
      contact: {
        ...prevState.contact,
        [name]: value.substring(0, 3000)
      }
    }));
      }
  };

 //show modal turns on tthe show state, that turns on the modal and passes props that set the animation behaviors of the modal
  showModal = () => {
      this.setState({ show: true, modalAnim: {animation:  'modalFade .3s ease-in'}, modalBackground: {animation:  'modalBack .3s ease-in'} });
    };

    // hide turns off the show state, and passes props that set the animation behaviors of the modal. After the setTimeout is run the modal animations are reset
   hideModal () {
     //animating the pull Out
    this.setState({  modalAnim: {animation:  'modalFadeOut .3s ease-in'}, modalBackground: {animation:  'modalBackOut .3s ease-in'} });
    // setting the timeout
    setTimeout(() => this.setState({ show: false, modalAnim: {animation:  'modalFade .3s ease-in'}, modalBackground: {animation:  'modalBack .3s ease-in'} }) , 289);

    };

    // work in progress
    // send the form object to firebase along with the country name and countryCode as one object then set the sucess state to true for the modal content. Show the modal and reset the content in the form
  async handleSubmit(e) {
        e.preventDefault();
        // debugger;
        // create refrence to the firebase segment handling contacts
          const {contactRef} = this.state;
          // create object to be sent to contact section
          const contact ={
              form: this.state.contact,
              nationName: this.state.nationName,
              nationCode: this.state.nationCode,
              timestamp: firebase.database.ServerValue.TIMESTAMP
                  }


              if (contact.form.phone === ''|| contact.form.phone.match(/[a-zA-Z]/i) ){
                contact.form.phone = 'N/A';
                contact.nationCode = 'N/A';
                contact.nationName = 'N/A';
                  }


          // Beginning of try block. Goal is to document if there is a connection, if yes push the object to firebase, otherwise throw an error and alter the modal that will appear
      try{

        let connectedRef = firebase.database().ref('.info/connected');
        connectedRef.once('value', (snap) => {
              // if firebase detects a connection
          if (snap.val() === true) {
              //push the contact object to firebase
            contactRef.push(contact);
              //then show the modal covering the screen
            this.setState({ success: true });
            this.showModal();
              // then clear the text boxes
            this.setState(prevState=>({
              ...prevState,
              contact: {
                name:'',
                phone: '',
                email: '',
                message: ''
              },
              phoneValid: false,
              formBackground:{}
            }));
            return 0;
          }
          // if a connection is not detected, set the error message then show the modal, retaining the
          else {
            this.setState({ success: false });
            this.showModal();
          }

      });


        }
        catch(error){
          console.log(error);
          this.setState({ success: false });
          this.showModal();
        }
}

handlePhone = (...obj) =>{
  // take the object returned by react IntlTelInput and use it to setState for the phone object limited to 30 characters

this.setState(prevState => ({
  ...prevState,
  contact: {
    ...prevState.contact,
    phone: obj[1].substring(0, 40)
  }
}));
  // react IntlTelInput returns an array,  obj[0] is a validation boolean, when validated, it will set the state of the phoneValid, nationCode and nationName form background will show a validation color if true, if false they will be emptied
if(obj[0]===true){
  this.setState(prevState => ({
    ...prevState,
    phoneValid: true,
    nationName: obj[2].name,
    nationCode: obj[2].dialCode,
    formBackground:{style:{"backgroundColor": "#A4DE95"}}
  }));
}

else if( obj[0] === false){
    this.setState(prevState => ({
      ...prevState,
      phoneValid: false,
      formBackground:{}
    }));
  }

}

// when the flag of the dropdown menu is clicked, resets the phone form and nation values put in state,
handleFlag = (...obj) => {
  this.setState(prevState => ({
    ...prevState,
    contact: {
      ...prevState.contact,
      phone: ''
    },
    phoneValid: false,
    nationName: obj[1].name,
    nationCode: obj[1].dialCode,
    defaultNationCode: obj[1].iso2,
    formBackground:{}
  }));
}

componentDidMount(){
  //scroll to top of page when component is mounted
    window.scrollTo(0, 0)
}

  render(){
    const overFlow = {
      justifyContent: 'center',
      maxWidth: '750px',
      height: 'auto',
      marginTop:'20px'
    }

    const formstyling = {
      display: 'flex',
      flexFlow: 'column'
    }

    const {contactinfo, store_hours} = this.props;

    return (
      <div className="contact">
          <MediaQueries maxWidth ={768}>
            {(matches) => {
              if (matches){
                return <img src={MobileShed} alt="shed" className="shed"/>
              }else{
                 return <img src={Shed} alt="shed" className="shed" style={overFlow}/>
              }
            }}
          </MediaQueries>
          <ContentModal show={this.state.show} handleClose={this.hideModal} closePhrase={contactinfo.closephrase}  modalAnim={this.state.modalAnim} modalBackground={this.state.modalBackground}>
          <p>{this.state.success===true?contactinfo.modal.success:contactinfo.modal.fail}</p>
        </ContentModal>
          <div className="spacer"> </div>
            <div className= "contactGold"> {/* Beginning of contact div placement*/ }
              <div className="contactContain" id="top">  {/* Beginning of contact div Elements*/ }
                <div id="contactStuff">
                  <h2><span>{contactinfo.question}</span></h2>
                  <h1><span>{contactinfo.title}</span></h1>
                  <p>{contactinfo.info.address}</p>
                  <p>{contactinfo.info.borough}, {contactinfo.info.state}</p>
                  <p><span>{contactinfo.info.phone.title}</span></p>
                  <p>{contactinfo.info.phone.number}</p>
                  <p><span>{contactinfo.info.secondphone.title}</span></p>
                  <p> {contactinfo.info.secondphone.number}</p>
                  <p><span>{contactinfo.info.hours.title}</span></p>
                  <div id="times">
                    <h1><span>{store_hours.mondays} {contactinfo.info.hours.time}</span></h1>
                    <h1><span>{store_hours.tuesdays} {contactinfo.info.hours.time}</span></h1>
                    <h1><span>{store_hours.odins_day} {contactinfo.info.hours.time}</span></h1>
                    <h1><span>{store_hours.thors_day} {contactinfo.info.hours.time}</span></h1>
                    <h1><span>{store_hours.fry_day} {contactinfo.info.hours.time}</span></h1>
                    <h1><span>{store_hours.sat_day} {contactinfo.info.hours.sat_time}</span></h1>
                    <h1><span>{store_hours.holi_hrs}</span></h1>
                  </div>
              <span> <p style={{marginTop:'0px', fontWeight:'bold'}}>{contactinfo.info.instagram}</p>  <a href="https://www.instagram.com/vibro_fabrique_s.a/"> <img src={Insta} alt="Logo instagram" className='insta' /> </a> </span>
                  <p style={{marginTop:'0px', fontWeight:'bold'}}>E-MAIL:</p>
                  <p>{contactinfo.info.email}</p>
                  {/* Try to add a button that sends an e-mail or a form that sends e-mails */}
                    <div className="contactFormContainer" style={{border:'1px solid black'}} >

                      <form style={formstyling} onSubmit={this.handleSubmit}>
                        <label> <span style={{color:'rgb(210, 31, 52)'}}>*</span>{contactinfo.contactform.name}:
                        <input type="text" name="name"
                          required
                          placeholder={contactinfo.contactform.nameplaceholder}
                          onChange={this.handleChange}
                           value={this.state.contact.name}
                          />
                        </label>
                        <label>Phone:
                          <span style={{color:'black'}}>
                          <IntlTelInput
                              preferredCountries={['ht','us','ca','do']}
                              onPhoneNumberChange={this.handlePhone}
                              value={this.state.contact.phone}
                              fieldName="phone"
                              defaultCountry={this.state.defaultNationCode}
                              onSelectFlag={this.handleFlag}
                              useMobileFullscreenDropdown={false}
                              format={true}
                              telInputProps={this.state.formBackground}
                              />
                                </span>
                         </label>
                          <label><span style={{color:'rgb(210, 31, 52)'}}>*</span>Email:
                          <input type="email"
                            required
                            name="email"
                            placeholder="email@email.com"
                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                            onChange={this.handleChange} value={this.state.contact.email} />
                          </label>
                        <label><span style={{color:'rgb(210, 31, 52)'}}>*</span>Message:
                            </label>
                            <textarea type="text" name="message" onChange={this.handleChange}
                              required
                              placeholder={contactinfo.contactform.messageplaceholder}
                              value={this.state.contact.message} />
                        <input type="submit" value={contactinfo.contactform.submit} />
                        <label style={{color:'rgb(210, 31, 52)','textAlign': 'right' }}>* {contactinfo.contactform.required}</label>

                        </form>
                    </div>{/* End of contact form Elements*/ }

                </div>

              </div> {/* End of contact div Elements*/ }
      </div>{/* End of contact div placement*/ }
          <div className="spacer">

          </div>

      </div>
      )
  }


  }
