import React, { Component } from 'react';

import {HashLink as Link} from 'react-router-hash-link';
import './index.css';

import CinderBlock from '../../resources/cinderblock_color.png';

import Construct from '../../resources/favicon_hardhat_color.png';
import MediaQueries from 'react-responsive';

// Component for welcome page
export default class Welcome extends Component{
  constructor(props){
    super(props);
    this.state={
        }
      }
      componentDidMount(){
          window.scrollTo(0, 0)
      }

      render(){
      const {home} = this.props;

  return (
    <div className="welcome">

        <MediaQueries maxWidth={764}>
          {(matches) => {
            if (matches) {
              return <div id="introInner-sm"></div>;
            }else {
              return <div id="introInner-reg"></div>;
            }
          }}
        </MediaQueries>

        <article className='introBox'>
            <h1>{home.title}</h1>
            <h2>{home.title_blurb}</h2>
            <Link id="servButton" to='/About'>
                {home.click}
            </Link>
        </article>

        <div className="serviceBlok">
          <h1>{home.service_blurb.service_title}</h1>
          <p>{home.service_blurb.service_blurb}</p>
          <div id="servInner">
            <div className="iconBlok">
              <Link id="Button" to='/Services#cinderBlok'>
              <img src={CinderBlock} alt={home.service_blurb.cinder_title} id="icon1"/>
            </Link>
              <h2>{home.service_blurb.cinder_title}</h2>
              <p>{home.service_blurb.cinder_blurb}</p>
            </div>
            <div className="iconBlok">
              <Link id="Button" to='/Services#contractBlok'>
              <img src={Construct} alt={home.service_blurb.contract_title} id="icon2" />
            </Link>
              <h2>{home.service_blurb.contract_title}</h2>
              <p> {home.service_blurb.contract_blurb}</p>
            </div>

          </div>
          <Link id="servButton" to='/Services#bigImage3'>
            {home.click}
          </Link>
        </div>
        <div id="bizHours">
        <h1>{home.store_hours.title}</h1>
          <div id="hours">
            <div id="hourBlok">
              <h2>
                <span>{home.store_hours.mondays}</span>
                {home.store_hours.hours}
              </h2>
            </div>
            <div id="hourBlok">
            <h2>
              <span>{home.store_hours.tuesdays}</span>
              {home.store_hours.hours}
            </h2>
            </div>
            <div id="hourBlok">
            <h2>
              <span>{home.store_hours.odins_day}</span>
              {home.store_hours.hours}
            </h2>
            </div>
            <div id="hourBlok">
            <h2>
              <span>{home.store_hours.thors_day}</span>
              {home.store_hours.hours}
            </h2>
            </div>
            <div id="hourBlok">
            <h2>
              <span>{home.store_hours.fry_day}</span>
              {home.store_hours.hours}
            </h2>
            </div>
            <div id="hourBlok">
            <h2>
              <span>{home.store_hours.sat_day}</span>
               {home.store_hours.sat_hours}
            </h2>
            </div>
            <div id="hourBlok">
            <h2>
              <span>{home.store_hours.holi_hrs}</span>
            </h2>
            </div>
          </div>
        </div>
        <div className="spacer"></div>
    </div>
    )
  }
  }
