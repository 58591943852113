
//english object, just expand to other tabs
const English={
  nav:{
    home: 'Home',
    about:"About",
    services:"Services",
    contact:"Contact",
  },
  home:{
    title: 'A Legacy of Excellence',
    title_blurb: 'A family run construction supply business that’s now under the guidance of the second generation of Desirs. VFD continues to make its own various types of high quality cinder block, windows repairs and installation, home repair and renovation; and the one that started it all — delivering construction and building materials with a small fleet of vehicles. Over a half of a century of continuous operation means customers get a trusted, reputable, reliable, knowledgeable, expert advice, a wealth of resources, established network of contractors, community-oriented experience from the family business.',
    service_blurb:{
      service_title:'OUR SERVICES',
      service_blurb:'A reputable retailer of all types of construction materials. Referals for window repair & installation, home repair & rennovation, construction & building material transportation',
      cinder_title: 'Construction Materials',
      cinder_blurb: 'We make cement blocks onsite, and on deamand carry cement, gravel, iron, wood, etc.',
      contract_title: 'Contracting',
      contract_blurb: 'We connect you with the best construction crews',
      window_title: 'Window Repair',
      window_blurb: 'We will repair your Windows',
      delivery_title: 'Material Delivery',
      delivery_blurb: 'From the port to the worksite'
    },
    click:'Click here for more',
    store_hours: {
      title: 'BUSINESS HOURS',
      mondays:"MONDAY ",
      tuesdays: "TUESDAY ",
      odins_day: "WEDNESDAY ",
      thors_day: "THURSDAY ",
      fry_day: "FRIDAY ",
      sat_day:'SATURDAY ',
      hours: '7AM - 5PM EDT',
      sat_hours:'7AM - 4PM EDT',
      holi_hrs:'CLOSED HOLIDAYS'
    }
  },
  contact: {
    question:'Have a project for VFD?',
    title: 'CONTACT US',
    info: {
    	address:'130 Martin L. King Avenue',
    	borough: 'Port-au-Prince',
    	state: 'Haiti',
    	phone: {
        title: 'Phone',
        number:'(509) 3476-7449'
      },
      secondphone: {
        title: 'Secondary Phone',
        number: '(509) 3743-9140'
      },
      hours: {
        title: 'Business Hours',
        time: '7:00 AM - 5:00 PM EDT',
        sat_time: '7:00 AM - 4:00 PM EDT'
      },
      email: 'ndesir28@yahoo.fr',
      instagram: 'Our Instagram'
    },
    contactform:{
      name: 'Name',
      nameplaceholder:'First name Last name',
      phone: 'phone',
      messageplaceholder: 'questions or inquiries',
      submit:'Submit',
      required:'Required'
    },
    modal:{
      success:'Your contacts were recieved, we will get back to you soon!',
      fail:'Something went wrong, wait and try again.'
    },
      closephrase:'CLOSE'

  },
  about:{
    title: 'About Us',
    subtitle: 'The story of Vibro Fabrique Desir',
    h1: ' Humble beginnings ',
    p1:`With humble beginnings as a one man operation, delivering construction materials with one truck. Durice Desir, with the help of his beloved wife and partner, Mariana Desir, founded Vibro Fabrique Desir in 1960. The long hours and backbreaking work has finally paid off as Vibro Fabrique Desir established itself in the neighboring vicinities. It has grown and has been proudly serving the Greater Port au Prince, Haiti community with over 50 years of operational experience.`,
    h2: 'A Legacy of Service',
    p2: 'A family run construction supply business that’s now under the guidance of the second generation of Desirs. VFD continues to make its own various types of high quality cinder block, windows repairs and installation, home repair and renovation; and the one that started it all — delivering construction and building materials with a small fleet of vehicles. Over a half of a century of continuous operation means customers get a trusted, reputable, reliable, knowledgeable, expert advice, a wealth of resources, established network of contractors, community-oriented experience from the family business.'
},

  services:{
    title: 'About our Services',
    title_p: 'The services we offer are concrete blocks, iron rebar, gravel and licensed contractor referal.',
    service1: {
      title: 'CONCRETE BLOCKS',
      about:'We make concrete block of different sizes onsite. Those include',
      ten:`10 cm x 20 cm x 40 cm`,
      twelve:`12 cm x 20 cm x 40 cm`,
      twenty:`20 cm x 20 cm x 40 cm`,
      thirty:`30 cm x 20 cm x 40 cm`
    },
    service2: {
      title: 'Iron Rebar',
      about: 'We make iron rebar of various sizes',
      half: ' 1/2 in',
      quarter: '1/4 in',
      eighth: '3/8 in'
    },
    service3: {
      title: 'Gravel and Crushed Stone',
      about: 'Materials sold by the truckload'
    },
    service4:{
        title: 'Wood',
        about: 'Lumber of various types offered on request'
    },
    service5:{
      title: 'Contractor Referrals',
      about: 'Licensed contractor referral - a network of reputable licensed contractors at our fingertips',
      materialtitle:'Materials Transport',
      materialbody: 'Transportation of building materials - with the proper permits. Contractors can provide transportation of building materials either from our store or from the port.',
      windowtitle: 'Licensed technitians to repair and install windows',
      windowbody: 'Windows installation & repair - licenced contractors to install, repair & replace windows'
    },
    servicemat:'Contact Us for Materials',
    servicecont:'Contact Us for Referrals'
  },
  adminlogin:{
      Employeenot:'Employee Login',
      passnplace:'Password',
      required:'Required',
      submit:'Submit'
  },
  error:{
    error:'404 Page Not Found'
  }


}

//french object, just expand to other tabs
const French={
  nav:{
    home:"Accueil",
    about:"Quelque",
    services:"Services",
    contact:"Contactez-nous",
  },
  home:{
    title: 'Un Heritage De Service',
    title_blurb: "Une entreprise familiale de matériaux de construction qui est maintenant dirigée par la deuxième génération de Desirs. VFD continue à fabriquer ses propres types de parpaings, de réparation et d’installation de fenêtres, de réparations et de rénovations résidentielles de haute qualité; et celui qui a tout déclenché - livrer des matériaux de construction et de construction avec une petite flotte de véhicules. Plus d'un demi-siècle de fonctionnement continu signifie que les clients obtiennent des conseils avisés, fiables, fiables et bien informés, une richesse en ressources, un réseau de sous-traitants établi et une expérience axée sur la communauté de l'entreprise familiale.",
    service_blurb:{
      service_title:'NOS SERVICES',
      service_blurb:'Une compagnie de construction que vous pouvez conpter pour tout.La réference pour tout les traveaux de réparation de fenêtre et installation, réparation et rennovation de maison, et pour les transports de matériaux de construction, est available.',
      cinder_title: 'Matériaux de Construction',
      cinder_blurb: 'Nous faisons des blocs de construction sur place. Et sur demande vous prouvez trouver des barres de fer, gravier, pierre concassée,et  bois, etc.',
      contract_title: 'Références des Contracteur',
      contract_blurb: 'Nous avons un réseau de confiance avec des contrecteurs expérimentés.'
    },
      click:'Cliquez ici pour plus'
      ,
    store_hours: {
      title: 'HEURES DU BUREAU',
      mondays:"LUNDI ",
      tuesdays: "MARDI ",
      odins_day: "MECREDI ",
      thors_day: "JEUDI ",
      fry_day: "VENREDI ",
      sat_day:'SAMEDI ',
      hours: '7AM - 5PM EDT',
      sat_hours:'7AM - 4PM EDT',
      holi_hrs:'VACANCES FERMEES'
    }
  },
  contact: {
    question:'Havez vous un projet pour VFD?',
    title: 'APPELE NOUS',
    info: {
    	address:'130 Martin L. King Avenue',
    	borough: 'Port-au-Prince',
    	state: 'Haïti',
    	phone: {
        title: 'A Ce Numéro',
        number: '(509) 3476-7449'
      },
      secondphone: {
        title:'Un Deuxieme Numéro',
        number: '(509) 3743-9140'
      },
      hours: {
        title:'HEURES DU BUREAU',
        time: '7:00 AM - 5:00 PM EDT',
        sat_time: '7:00 AM - 4:00 PM EDT'
      },
      email: 'ndesir28@yahoo.fr',
      instagram: 'Notre Instagram'
    },
    contactform:{
      name: 'Nom',
      nameplaceholder:'Prénom nom de famille',
      phone: 'phone',
      messageplaceholder: 'questions ou demandes de renseignements',
      submit:'Soumettre',
      required:'Requis'
    },
    modal:{
      success:'Vos contacts ont été reçus, nous vous contacterons bientôt!',
      fail:"Quelque chose s'est mal passé, attendez et essayez à nouveau."
    },
      closephrase:'fermé'

      },
  about:{
    title: 'Qui sommes-nous ?',
    subtitle: "L'Histoire de Vibro Fabrique Desir",
    h1: 'Humble débuts',
    p1:`Avec ses modestes débuts comme entreprise individuelle, livrant des matériaux de construction avec un seul camion. Durice Desir, avec l'aide de son épouse et partenaire bien-aimée, Mariana Desir, a fondé Vibro Fabrique Desir en 1960. Les longues heures de travail et le travail éreintant ont finalement porté leurs fruits puisque Vibro Fabrique Desir s'est établi dans les environs voisins. L'entreprise a grandi et sert avec fierté la communauté haïtienne du Grand Port-au-Prince avec plus de 50 ans d'expérience opérationnelle.`,
    h2: 'Un Heritage De Service',
    p2: "Une entreprise familiale de matériaux de construction qui est maintenant dirigée par la deuxième génération de Desirs. VFD continue à fabriquer ses propres types de parpaings, de réparation et d’installation de fenêtres, de réparations et de rénovations résidentielles de haute qualité; et celui qui a tout déclenché - livrer des matériaux de construction et de construction avec une petite flotte de véhicules. Plus d'un demi-siècle de fonctionnement continu signifie que les clients obtiennent des conseils avisés, fiables et bien informés, une richesse en ressources, un réseau de sous-traitants établi et une expérience axée sur la communauté de l'entreprise familiale."
  },
  services:{
    title: 'A propos de nos services',
    title_p: "Une compagnie de construction que vous pouvez compter sur nous. La réference pour tous les traveaux de réparation de fenêtre et installation, réparation et rennovation de maison, et pour les transports de matériaux de construction, sont disponible.",
    service1: {
      title: 'Blocs de construction',
      about:'Nous fabriquons des blocs de construction de différentes tailles sur place.Dans les types de',
      ten:`10 cm x 20 cm x 40 cm`,
      twelve:`12 cm x 20 cm x 40 cm`,
      twenty:`20 cm x 20 cm x 40 cm`,
      thirty:`30 cm x 20 cm x 40 cm`
    },
    service2: {
      title: 'Barres de fer',
      about: 'Nous fabriquons des barres de fer de différentes tailles sur place.',
      half: ' 1/2 in',
      quarter: '1/4 in',
      eighth: '3/8 in'
    },
    service3: {
      title: 'Gravier et Pierre concassée',
      about: 'Matériaux vendus par camion'
    },
    service4:{
      title: 'Bois',
      about: 'Les différents types de bois pour construction sur demande.'
    },
    service5:{
      title: 'Références des Contracteur',
      about: 'Référence des contracteur autorisés - un réseau des contracteurs agréés et réputés au bout de nos doigts',
      materialtitle:'Transport de matériaux',
      materialbody: 'Transport des matériaux de construction - avec les permis appropriés. Les contracteurs peuvent assurer le transport des matériaux de construction depuis de notre installation ou depuis le port.',
      windowtitle: 'Techniciens licenciés pour réparer et installer des fenêtres.',
      windowbody: 'Installation et réparation des fenêtres - contracteurs autorisés à installer, réparer et remplacer des fenêtres'
    },
    servicemat:'Contactez nous pour les Matériaux',
    servicecont:'Contactez nous pour les Renvois'
  },
  adminlogin:{
      Employeenot:'Login Employé',
      passnplace:'Mot de passe',
      required:'Requis',
      submit:'Soumettre'
  },
  error:{
    error:'404 Page Non Trouvée'
  }
};

//exporting it
export{
  English,
  French
}
