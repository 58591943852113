import React, { Component } from 'react';
import { Route, Link, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';

import './css/App.css';
// import './resources/webfontkit-rubik/stylesheet.css';
//important objects of english and french
import {English, French} from './languages.js';

//components for segments of the page
import Footer from './components/footer';

//components for each view
import About from './components/about';
import Contact from './components/contact';
import Services from './components/services';
import Welcome from './components/welcome';
import Unknown from './components/unknown';
import AdminLogin from './components/AdminLogin';
import AdminPanel from './components/AdminPanel';

//mediaQueries
import MediaQueries from 'react-responsive';

//large logo
import VFDLong from './resources/VFDlogo_wTitle_nobars.png';
import FourBars from './resources/4bars.jpg';
// small logo
import VFDlogo from './resources/VFDlogo_wTitle.png';

//analyitics tracking
import Analytics from 'react-router-ga';

class App extends Component {
      constructor(props){
        super(props);
        this.state={
          english: English,
          french: French,
          user: JSON.parse(localStorage.getItem('VFDuser')) || null,
          //set the language to start from a saved localStorage item or a string
          language: localStorage.getItem('savelang')||'french',
          authState:JSON.parse(localStorage.getItem('auth')) || null,
          creds:JSON.parse(localStorage.getItem('creds')) || null
        }
        this.changeLanguage = this.changeLanguage.bind(this);
        this.setUser = this.setUser.bind(this);
        this.setAuth = this.setAuth.bind(this);
        this.setCreds = this.setCreds.bind(this);
      }
      changeLanguage=(e)=>{
        this.setState({
          language: e
        })
      }
      // cose to set the user object from firebase here
      setUser = (usr) =>{
        this.setState({
          user:usr
        })
      }
      //set the authState from firebase here for authenication for admin purposes
      setAuth = (auth) =>{
        this.setState({
          authState: auth
        })
      }

      //set credentials from firebase to adjust different views for different permission levels

      setCreds = (admin,rank) =>{
            if((admin !== undefined || null) && (rank !== undefined || null) ){

        this.setState( prevState => ({
          ...prevState,
          creds:{
            admin: admin,
            rank: rank
          }
        }));
      }else{
        this.setState( prevState => ({
          ...prevState,
          creds: null
        }));

        }
      }

      componentDidMount(){
        // add addEventListener to window
        window.addEventListener('beforeunload', () => {
          localStorage.setItem('savelang', this.state.language);
          localStorage.setItem('auth', this.state.authState);
          localStorage.setItem('VFDuser', JSON.stringify(this.state.user));
          localStorage.setItem('creds', JSON.stringify(this.state.creds));
        } );
      }
      componentWillUnmount(){
        //use the removal of the EventListener to create and set the local storage item
        window.removeEventListener("beforeunload", () => {
          localStorage.setItem('savelang', this.state.language);
          localStorage.setItem('auth', this.state.authState);
          localStorage.setItem('VFDuser', JSON.stringify(this.state.user));
          localStorage.setItem('creds', JSON.stringify(this.state.creds));
        })
      }

  render (){
    const {language, user, creds }=this.state;
    const {english,french}=this.state;

    const logoMain = {
      width: '100%',
      marginTop:'6%'
    }

    const PrivateRoute = () => (
  <Route render={(props) => (
    this.state.authState === true && this.state.user !== null
      ? <AdminPanel user={user} setUser={this.setUser} setAuth={this.setAuth} setCreds={this.setCreds} language={language} creds={creds} />
      : <Redirect to='/Admin' />
  )} />
)

  const AdminRoute = () => (
  <Route render={(props) => (
  this.state.authState === true && this.state.user !== null
    ? <Redirect to='/Panel' />
    : <AdminLogin adminlogin={language==='english'?english.adminlogin:french.adminlogin} setUser={this.setUser}  user={user}  setCreds={this.setCreds} setAuth={this.setAuth}  creds={creds}/>
  )} />
  )

      return (
        <Router>
            <Analytics id="UA-141293228-1" >
      <div className="App">
        <nav className="Nav">

        <Link to={{
            pathname: '/',
            state: {
              thisInfo: `${language==='english'?english.home:french.home}`
            }
        }}>{language==='english'?english.nav.home:french.nav.home}
        </Link>

        <Link to={{
            pathname: '/About',
            state: {
              thisInfo: `${language==='english'?english.about:french.about}`
            }
        }}>{language==='english'?english.nav.about:french.nav.about}
        </Link>

        <Link to={{
            pathname: '/Services',
            state: {
              thisInfo: `${language==='english'?english.services:french.services}`
          }
        }}>{language==='english'?english.nav.services:french.nav.services}
        </Link>

        <Link to={{
            pathname: '/Contact',
            state: {
              thisInfo: `${language==='english'?english.contact.info:french.contact.info}`
          }
        }}>{language==='english'?english.nav.contact:french.nav.contact}
        </Link>

        </nav>

        <div className="spacer">

        </div>

    {/* transplanting parablox*/}
    <div className="paraBlox" id="logobox">
      <MediaQueries maxWidth={768}>
      {(matches) => {
        if (matches) {
          return <div className = "logo">
                    <img src={VFDlogo} alt="titre de logo" style={logoMain}/>
                </div>
        } else {
          return <div className="logo">
                    <img src={VFDLong} id="longLogo" alt="title_logo" />
                    <img src={FourBars} id="fourBars" alt="decoration" />
                  </div>
        }
      }}

      </MediaQueries>
    </div>

    {/* transplanting parablox END*/}
      <div className="viewCont">
        <Switch>
        <Route
           exact path='/'
          render={(props) => <Welcome home={language==='english'?english.home:french.home} />}
          />

        <Route
          path='/About'
          render={(props) => <About about={language==='english'?english.about:french.about} contactinfo={language==='english'?english.contact:french.contact} store_hours={language==='english'?english.home.store_hours:french.home.store_hours} />}
          />

        <Route
          path='/Services'
          render={(props) => <Services services={language==='english'?english.services:french.services} />}
          />

        <Route
          path='/Contact'
          render={(props) => <Contact contactinfo={language==='english'?english.contact:french.contact} store_hours={language==='english'?english.home.store_hours:french.home.store_hours} />}
          />

          <AdminRoute path='/Admin'  />

          <PrivateRoute path='/Panel'  />

        <Route
          path ='*'
          render= { (props)=> <Unknown error={language==='english'?english.error:french.error} /> }
        />

      </Switch>

      </div>
          <Footer currentlang={language} changeLanguage={this.changeLanguage} />

      </div>
      </Analytics>
  </Router>

    );
  }
}
export default App;
