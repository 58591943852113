import React from 'react';
import './index.css';
export default function ContentModal(props){

const showHideClassName = props.show ? 'modal display-block' : 'modal display-none';

return (
  <div className={showHideClassName} style={props.modalBackground}>
    <section className='modal-main' style={props.modalAnim}>
      {props.children}
      <button
        onClick={props.handleClose}
        className='modalbutton'
      >
        {props.closePhrase.toUpperCase()}
      </button>
    </section>
  </div>

  )
}
