//english object, just expand to other tabs
const EnglishAdmin={
  panel: 'Administration Panel',
  panelNav:{
    logout:'Logout',
    regadmin: 'Register Admin',
    manadmin: 'Manage Admin',
    contacts: 'Contacts'
    },
  adminReg:{
    header: 'Register Admins',
    name: 'Name',
    password: 'Password',
    submit: 'Submit',
    required: '* Required',
    modal:{
      success:'Admin Registered successfully',
      fail:'Something went wrong, wait and try again.'
    },
      closephrase:'close'
  },
  adminRemove:{
    header:'Admin List',
    empty:'Admins Emptied',
    uploaded:'Registered on:',
    name:'Name:',
    rank:'Rank:',
    delete: 'Delete Admin',
    raise: 'Raise Level',
    lower:  'Lower Level',
    modal:{
      deletesuccess:'Admin Removed successfully',
      fail:'Something went wrong, wait and try again.',
      raisesuccess:'Admin Upgraded successfully',
      lowersuccess:'Admin Demoted successfully',
      confirm: 'CONFIRM',
      deleteconfirm:'Are you sure you want to delete the administrator?',
      raiseconfirm:'Are you sure you want to upgrade the admin?',
      lowerconfirm:'Are you sure you want to demote the admin?'
    },
      closephrase:'close'
  },
  adminContact:{
    header:'Contacts List',
    empty:'Contacts Emptied',
    uploaded:'Uploaded on:',
    name:'Name:',
    country:'Country:',
    countryCode:'Country Code:',
    delete: 'Delete Contact',
    modal:{
      success:'Contact successfully deleted',
      fail:'Something went wrong, wait and try again.'
    },
      closephrase:'close'
  }

};

//french object, just expand to other tabs
const FrenchAdmin={
  panel: `Paneau d'administration`,
  panelNav:{
    logout:'Logout',
    regadmin: 'Enregistrer Admin',
    manadmin: 'Console Admin',
    contacts: 'Contacts'
    },
  adminReg:{
    header: 'Enregistrer les Administrateur',
    name: 'Nom',
    password: 'Mot de Passe',
    required: '* Requis',
    submit: 'Soumettre',
    modal:{
      success:'Administrateur Enregistré Avec Succès',
      fail:"Quelque chose s'est mal passé, attendez et essayez à nouveau."
    },
      closephrase:'FERME'
  },
  adminRemove:{
    header:'Liste de Admin',
    empty:'Admins Vider',
    uploaded:'Enregistré sur:',
    name:'Nom:',
    rank:'Rang:',
    delete: 'Supprimer Admin',
    raise: 'Promouvoir',
    lower: 'Rétrogradé',
    modal:{
      deletesuccess:'Administrateur Supprimer Avec Succès',
      fail:"Quelque chose s'est mal passé, attendez et essayez à nouveau.",
      raisesuccess:'Administrateur Promouvoir Avec Succès',
      lowersuccess:'Administrateur Rétrogradé Avec Succès',
      confirm:'CONFIRMER',
      deleteconfirm:`Voulez-vous vraiment supprimer l'administrateur?`,
      raiseconfirm:`Voulez-vous vraiment promouvoir l'administrateur?`,
      lowerconfirm:`Voulez-vous vraiment rétrograder l'administrateur?`
    },
      closephrase:'FERME'
  },
  adminContact:{
    header:'Liste de Contacts',
    empty:'Contacts Vider',
    uploaded:'Téléchargé sur:',
    name:'Nom:',
    country:'Pays:',
    countryCode:'Code de Pays:',
    delete: 'Supprimer Contact',
    modal:{
      success:'Contact Supprimer Avec Succès',
      fail:"Quelque chose s'est mal passé, attendez et essayez à nouveau."
    },
      closephrase:'FERME'
  }
};

//exporting it
export{
  EnglishAdmin,
  FrenchAdmin
}
