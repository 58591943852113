import React, { Component } from 'react';
import './index.css';
// import {Redirect} from 'react-router-dom';
import PanelCard from '../panelcard';
import AdminPanelCard from '../panelAdmin';
import firebase from '../../firebase';
import ContentModal from '../contactModal';

import {EnglishAdmin , FrenchAdmin} from './adminlang.js';

export default class AdminPanel extends Component {

  constructor(props){
    super(props);
    this.state={
      //languages in the page
      engAdmin: EnglishAdmin,
      frAdmin: FrenchAdmin,
      //handling of modal to show confirmation and error messages
      show:false,
      success:true,
      modalAnim: {animation: 'modalFade .3s ease-in'},
      modalBackground: {animation: 'modalBack .3s ease-in'},
      //arrays to display contact and admins
      contacts:[],
      admins:[],
      // refrences to contact and admins to get or manipulate contacts
      contactsRef:firebase.database().ref('contact'),
      adminsRef: firebase.database().ref('admin'),
      // which screen will be seen contact, admin or admin register
      currentview: JSON.parse(localStorage.getItem('AdState')) ||'contact',
      // state for adding an admin and error array
      email:"",
      password:"",
      name:"",
      errors:[],

      // state for confirmControl
      confirmK:null,
      confirmE:null,
      confirmF:null
        }
        // displays a line if empty for contact listing and admins
        this.emptyContacts = this.emptyContacts.bind(this);
        this.emptyAdmin = this.emptyAdmin.bind(this);
        // remove an entry for contact list or admin
        this.deleteContact = this.deleteContact.bind(this);
        this.deleteAdmin = this.deleteAdmin.bind(this);
        // upgrade or downgrade a user
        this.upgradeAdmin = this.upgradeAdmin.bind(this);
        this.downgradeAdmin = this.downgradeAdmin.bind(this);
        //logout the user, delete stuff from local storage
        this.logout = this.logout.bind(this);
        // change what controls the user can view
        this.navAdmin = this.navAdmin.bind(this);
        this.viewControl = this.viewControl.bind(this);
        this.handleView = this.handleView.bind(this);
        //submission and form handling for admin entry
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        // error display for admin registration
        this.displayErrors = this.displayErrors.bind(this);
      //  this.contactListener = this.contactListener.bind(this);
        // running a test listener to have a child added
        this.testListener = this.testListener.bind(this);
      // a function for admin listener
        this.adminListener = this.adminListener.bind(this);
      //showing a modal or hiding a modal
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
      // change content modal text then execute on confirmation and state
      this.confirmControl = this.confirmControl.bind(this);
      this.confirmState = this.confirmState.bind(this);
      }



      emptyContacts = () =>{
        const {language} = this.props;
        const {engAdmin, frAdmin, contacts, success} = this.state;

        if(contacts.length ===  0){
          return(
            <h1>{language === 'english'? engAdmin.adminContact.empty:frAdmin.adminContact.empty}</h1>
          )
        }else{
            //gonna map though the array and put each contact in a box
          return(
              <>
              <ContentModal show={this.state.show} handleClose={this.hideModal} closePhrase={language === 'english'? engAdmin.adminContact.closephrase:frAdmin.adminContact.closephrase}  modalAnim={this.state.modalAnim} modalBackground={this.state.modalBackground}>
                <p>{(success === true)?(language === 'english'? engAdmin.adminContact.modal.success:frAdmin.adminContact.modal.success):(language === 'english'? engAdmin.adminContact.modal.fail:frAdmin.adminContact.modal.fail) }</p>
              </ContentModal>
              <h1>{language === 'english'? engAdmin.adminContact.header:frAdmin.adminContact.header}</h1>
            {this.state.contacts.map( obj =>
              <PanelCard obj={obj} deleteContact={this.deleteContact} key={obj.key}
                language={language}
                engAdminCont={engAdmin.adminContact} frAdminCont={frAdmin.adminContact}
              />
            ) }
          </>
          )
        }
      }

      emptyAdmin = () =>{
        const {language} = this.props;
        const {engAdmin, frAdmin, admins, show, modalAnim, modalBackground, confirmK, confirmE, confirmF } = this.state;

        if(admins.length ===  0){
          return(
            <h1>{language === 'english'? engAdmin.adminRemove.empty:frAdmin.adminRemove.empty}</h1>
          )
        }else{
            //gonna map though the array and put each Admin in a box
          return(
            <>
            <ContentModal show={show} handleClose={this.hideModal} closePhrase={language === 'english'? engAdmin.adminRemove.closephrase:frAdmin.adminRemove.closephrase} modalAnim={modalAnim} modalBackground={modalBackground}>
              {this.confirmControl(confirmK, confirmE, confirmF) }
            </ContentModal>
              <h1>{language === 'english'? engAdmin.adminRemove.header:frAdmin.adminRemove.header}</h1>
          {this.state.admins.map( obj =>
            <AdminPanelCard obj={obj} key={obj.timestamp}
              language={language} engAdmin={engAdmin.adminRemove} frAdmin={frAdmin.adminRemove}
              confirmState={this.confirmState} />
          ) }
          </>
          )
        }
      }

      handleView(view){
      this.setState({
        currentview: view
      });

      localStorage.setItem('AdState', JSON.stringify(view));

      }

      navAdmin(){
        const {language, creds} = this.props;
        const {engAdmin, frAdmin} = this.state

         if (creds.admin === true) {
          return (
            <span className='consp' style={{justifyContent:'center', flexFlow:'row'}}>
              <button onClick={() => this.logout()}> {language === 'english'? engAdmin.panelNav.logout:frAdmin.panelNav.logout} </button>
              <button onClick={() => this.handleView('adminregister')}> {language === 'english'?engAdmin.panelNav.regadmin:frAdmin.panelNav.regadmin} </button>
              <button onClick={() => this.handleView('admin')}> {language === 'english'? engAdmin.panelNav.manadmin:frAdmin.panelNav.manadmin} </button>
              <button onClick={() => this.handleView('contacts')}>{language === 'english'? engAdmin.panelNav.contacts:frAdmin.panelNav.contacts} </button>
            </span>
            )
          }else{
          return (
      <span className='consp' style={{justifyContent:'center', flexFlow:'row'}}>
      <button onClick={() => this.logout()}> {language === 'english'? engAdmin.panelNav.logout:frAdmin.panelNav.logout} </button>
      <button onClick={() => this.handleView('contacts')}> {language === 'english'? engAdmin.panelNav.contacts:frAdmin.panelNav.contacts} </button>
      </span>
          )
        }
      }


      viewControl(view){

         const formstyling = {
           display: 'flex',
           flexFlow: 'column'
         }

         const {language} = this.props;
         const {engAdmin, frAdmin, show, success, errors, name, email, password} = this.state;

      switch(view) {

          case 'admin': return ( <>{this.emptyAdmin()}</> )

          case 'adminregister':return ( <>
            <ContentModal show={show} handleClose={this.hideModal} closePhrase={language === 'english'? engAdmin.adminReg.closephrase:frAdmin.adminReg.closephrase} modalAnim={this.state.modalAnim} modalBackground={this.state.modalBackground}>
              <p>{ (success === true)? (language === 'english'? engAdmin.adminReg.modal.success:frAdmin.adminReg.modal.success):(language === 'english'? engAdmin.adminReg.modal.fail:frAdmin.adminReg.modal.fail)}</p>
            </ContentModal>
                <h1>{language === 'english'? engAdmin.adminReg.header:frAdmin.adminReg.header}</h1>
            <div className="createAdminContainer" style={{border:'1px solid black'}} >
                  {this.displayErrors(errors)}
                    <form style={formstyling} onSubmit={this.handleSubmit}>
                      <label> <span style={{color:'rgb(210, 31, 52)'}}>*</span>{language === 'english'? engAdmin.adminReg.name:frAdmin.adminReg.name}:
                      <input type="text" name="name"
                        required
                        placeholder={language === 'english'? engAdmin.adminReg.name:frAdmin.adminReg.name}
                        onChange={this.handleChange}
                         value={name}
                        />
                      </label>

                        <label><span style={{color:'rgb(210, 31, 52)'}}>*</span>Email:
                        <input type="email"
                          required
                          name="email"
                          placeholder="email@email.com"
                          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                          onChange={this.handleChange} value={email} />
                        </label>

                        <label> <span style={{color:'rgb(210, 31, 52)'}}>*</span>{language === 'english'? engAdmin.adminReg.password:frAdmin.adminReg.password}:
                        <input type="password" name="password"
                          required
                          placeholder={language === 'english'? engAdmin.adminReg.password:frAdmin.adminReg.password}
                          onChange={this.handleChange}
                          value={password}
                          />
                        </label>
                        <input type="submit" value={language === 'english'? engAdmin.adminReg.submit:frAdmin.adminReg.submit} />
                      <label style={{color:'rgb(210, 31, 52)','textAlign': 'right' }}>{language === 'english'? engAdmin.adminReg.required:frAdmin.adminReg.required}</label>

                      </form>
                  </div>{/* End of contact form Elements*/ }

                      </> )

          default: return ( <>{this.emptyContacts() }</> )

          }

      }


      deleteContact = (key) => {
        //use firebase magic here and pass as prop to the PanelCard
        this.state.contactsRef.child(key).remove().then( () => {
        this.testListener(); // update array for display
        this.setState(prevState => ({ ...prevState, success: true }));
        this.showModal()
        }).catch(err => {
        console.error(err);
        this.setState({errors:this.state.errors.concat(err)});
        // modal displays error
        this.setState(prevState => ({ ...prevState, success: false }));
        this.showModal()
      })
         //remove entry

        //trigger modal to open after successful delete
      }

      deleteAdmin = async(key, email) => {
        //use firebase magic here and pass as prop to the AdminPanelCard
        const deleteAdmin = firebase.functions().httpsCallable('removeUser');
        deleteAdmin({email:email, key:key}).then(result =>{
          console.log(result);
          console.log('deletion complete');
          this.adminListener();
          this.setState(prevState => ({ ...prevState, success: true, confirmF:'deleteresult' }))
          // a modal would go here
        }).catch(err => {
        console.error(err);
        this.setState({errors:this.state.errors.concat(err)});
        // modal displays error
        this.setState(prevState => ({ ...prevState, success: false, confirmF:'deleteresult' }))
      })

      }

      upgradeAdmin = async(key, email) =>{
        //use firebase magic here and pass as prop to the AdminPanelCard
      const addAdminRole =  firebase.functions().httpsCallable('addAdminRole');
      addAdminRole({ email:email, key:key}).then(result =>{
      console.log(result);
      console.log('upgrade complete');
      this.adminListener();
    // a modal would go here
    this.setState(prevState => ({ ...prevState, success: true, confirmF:'upresult' }));
    // this.showModal();
  }).catch(err => {
        console.error(err);
        this.setState({errors:this.state.errors.concat(err)});
        // modal displays error
        this.setState(prevState => ({ ...prevState, success: false, confirmF:'upresult' }));
        // this.showModal()
      })

      }

      downgradeAdmin = async(key, email) => {
        //use firebase magic here and pass as prop to the AdminPanelCard
        const removeAdminRole =  firebase.functions().httpsCallable('removeAdminRole');
      removeAdminRole({email:email, key:key }).then(result =>{
      console.log(result);
      console.log('downgrade complete');
      this.adminListener();
      // a modal would go here
      this.setState(prevState => ({ ...prevState, success: true, confirmF:'downresult' }));
      // this.showModal();
    }).catch(err => {
        console.error(err);
        this.setState({errors:this.state.errors.concat(err)});
        // modal displays error
        this.setState(prevState => ({ ...prevState, success: false, confirmF:'downresult' }));
        // this.showModal();
      })

      }

      logout = () => {
        // clear out user object and local storage if there is any then redirect to /admin page
        localStorage.removeItem('auth');
        localStorage.removeItem('creds');
        localStorage.removeItem('AdState');
        this.props.setCreds(null);
        this.props.setAuth(null);
        firebase.auth().signOut();
        this.props.setUser(null);
        // <Redirect to="/admin" />
      }

      //hangle change evaluates the inputs and will limit the input length of name and email to 80 characters, the message will be limited to ten thousand characters
      handleChange = (e) => {
      const{ name, value } = e.target;

      if(name === 'name' ) {
      this.setState(prevState => ({

          ...prevState,
          [name]: value.substring(0, 280)

      }));
    } else if( name === 'email' ) {
    this.setState(prevState => ({

        ...prevState,
        [name]: value.substring(0, 280)

    }));
  } else {
    this.setState(prevState => ({

        ...prevState,
        [name]: value.substring(0, 3000)

      }));
    }
};


async handleSubmit(e) {
      e.preventDefault();
      // debugger;
      // create refrence to the firebase segment handling contacts

        const admin = {
          name: this.state.name,
          email: this.state.email,
          password:this.state.password,
          timestamp: firebase.database.ServerValue.TIMESTAMP
        }
                  // Beginning of try block. Goal is to document if there is a connection, if yes push the object to firebase, otherwise throw an error and alter the modal that will appear
              try{

                let connectedRef = firebase.database().ref('.info/connected');
                connectedRef.once('value', (snap) => {
                      // if firebase detects a connection, create a user then make a user object with a permission level/ maube do a permission check?
                  if (snap.val() === true) {
                      //push the create user then use cloud function to make a database entry
                      const createAdmin = firebase.functions().httpsCallable('createUser');
                      //send a signal to user maybe a modal
                      createAdmin({name:admin.name, email:admin.email, password:admin.password, timestamp:admin.timestamp })
                      .then(result =>{
                        console.log(result)
                        console.log('user created!')
                        // reset state and show modal
                        this.setState(prevState => ({

                            ...prevState,
                            name: '',
                            password:'',
                            email:'',
                            success:true

                        }))

                        this.showModal()
                      return 0

                      }).catch(err => {
                      console.error(err);
                      this.setState({errors:this.state.errors.concat(err)});
                      // modal displays error
                      this.setState(prevState => ({ ...prevState, success: false }));
                      this.showModal();
                    })

      }     // if a connection is not detected, set the error message then show the modal, retaining the
        else {
          let err = 'error: disconnected, try again';
            console.error(err);
            this.setState({errors:this.state.errors.concat(err)});
          //failure message and retain form state
          this.setState(prevState => ({ ...prevState, success: false }));
          this.showModal();
        }

    });


      }
      catch(err){
        console.error(err);
        this.setState({errors:this.state.errors.concat(err)});
        // failure message and retain form state
        this.setState(prevState => ({ ...prevState, success: false }));
        this.showModal();
      }
}

  displayErrors = errors => errors.map((error, i) => <p key={i}>{error.message}</p>);

  testListener = async() =>{

    let testContacts = [];

    let ref = this.state.contactsRef;
    // debugger;
    ref.on('child_added', snap =>{
      let snapKey = snap.val();
       snapKey.key = snap.key

      testContacts.push(snapKey);
      console.log('test key is new key', snapKey.key);
      console.log('testContacts has added', testContacts);

      this.setState({
        contacts: testContacts
      });

    })// end of child added

    ref.on('child_removed', snap =>{
      let snapKey = snap.val();
       snapKey.key = snap.key

      console.log('test key is removed key', snapKey.key);
      console.log('testContacts has removed', testContacts);

      this.setState({
        contacts: testContacts
      });

    })//end of child removed
      console.log('testContacts at end of function', testContacts);

        this.setState({
          contacts: testContacts
        });

  }

  adminListener = async() =>{

    let testAdmins = [];

    let ref = this.state.adminsRef;
    // debugger;
    ref.on('child_added', snap =>{
      let snapKey = snap.val();
       snapKey.key = snap.key

      testAdmins.push(snapKey);
      console.log('test admins key is new key', snapKey.key);
      console.log('testAdmins has added', testAdmins);

      this.setState({
        admins: testAdmins
      });

    })// end of child added

    ref.on('child_removed', snap =>{
      let snapKey = snap.val();
       snapKey.key = snap.key

      console.log('test admins key is removed key', snapKey.key);
      console.log('testAdmins has removed', testAdmins);

      this.setState({
        admins: testAdmins
      });

    })//

    ref.on('child_changed', snap =>{
      let snapKey = snap.val();
       snapKey.key = snap.key

      console.log('test admins key is modified key', snapKey.key);
      console.log('testAdmins has modded', testAdmins);

      this.setState({
        admins: testAdmins
      });

    })//

      console.log('testAdmins at end of function', testAdmins);

        this.setState({
          admins: testAdmins
        });

  }



  showModal = () => {
      this.setState({ show: true, modalAnim: {animation:  'modalFade .3s ease-in'}, modalBackground: {animation: 'modalBack .3s ease-in'} });
    };

    // hide turns off the show state, and passes props that set the animation behaviors of the modal. After the setTimeout is run the modal animations are reset
   hideModal () {
     //animating the pull Out
    this.setState({  modalAnim: {animation:  'modalFadeOut .3s ease-in'}, modalBackground: {animation:  'modalBackOut .3s ease-in'} });
    // setting the timeout
    setTimeout(() => this.setState({ show: false, modalAnim: {animation:  'modalFade .3s ease-in'}, modalBackground: {animation:  'modalBack .3s ease-in'} }) , 289);

    };


    // change content modal text then execute on confirmation
     confirmControl = (key, email, call) =>{
       const {language} = this.props;
       const {engAdmin, frAdmin, success}  = this.state
       const buttonStyle = {margin:'15px auto'}
      //if delete admin
      //return admin confirm
     // if upgrade admin
     //return upgrade confirm
     //if downgrade admin
      if (call === 'upgrade'){
        return(
          <>
          <p>{(language === 'english')? engAdmin.adminRemove.modal.raiseconfirm: frAdmin.adminRemove.modal.raiseconfirm}</p>
          <button style={buttonStyle} onClick={() => this.upgradeAdmin(key,email)}>{(language === 'english')? engAdmin.adminRemove.modal.confirm :frAdmin.adminRemove.modal.confirm}</button>
        </>
        )
      }
      else if (call === 'downgrade'){
        return(
          <>
          <p>{(language === 'english')? engAdmin.adminRemove.modal.lowerconfirm:frAdmin.adminRemove.modal.lowerconfirm}</p>
          <button style={buttonStyle} onClick={() => this.downgradeAdmin(key,email)}>{(language === 'english')? engAdmin.adminRemove.modal.confirm :frAdmin.adminRemove.modal.confirm}</button>
        </>
        )
      }
      else if (call === 'delete'){
        return(
          <>
          <p>{(language === 'english')? engAdmin.adminRemove.modal.deleteconfirm:frAdmin.adminRemove.modal.deleteconfirm}</p>
          <button style={buttonStyle} onClick={() => this.deleteAdmin(key,email)}>{(language === 'english')? engAdmin.adminRemove.modal.confirm :frAdmin.adminRemove.modal.confirm}</button>
        </>
        )
      }

      else if (call === 'upresult'){
        return(
          <>
          <p>{(success === true)?(language === 'english'? engAdmin.adminRemove.modal.raisesuccess:frAdmin.adminRemove.modal.raisesuccess):(language === 'english'? engAdmin.adminContact.modal.fail:frAdmin.adminContact.modal.fail) }</p>
        </>
        )
      }

      else if (call === 'downresult'){
        return(
          <>
          <p>{(success === true)?(language === 'english'? engAdmin.adminRemove.modal.lowersuccess:frAdmin.adminRemove.modal.lowersuccess):(language === 'english'? engAdmin.adminContact.modal.fail:frAdmin.adminContact.modal.fail) }</p>
        </>
        )
      }

      else if (call === 'deleteresult'){
        return(
          <>
          <p>{(success === true)?(language === 'english'? engAdmin.adminRemove.modal.deletesuccess:frAdmin.adminRemove.modal.deletesuccess):(language === 'english'? engAdmin.adminContact.modal.fail:frAdmin.adminContact.modal.fail) }</p>
        </>
        )
      }

      else{
      return  <p> admin action null line </p>
      }

    }

    confirmState = (k, e, f) => {
      this.setState(prevState => ({ ...prevState, confirmK:k, confirmE:e, confirmF:f }));
      this.showModal();
    }



  componentDidMount(){
      window.scrollTo(0, 0);
      // add addEventListener to window to create and set the local storage item to save persistently
      window.addEventListener('beforeunload', () => {
        localStorage.setItem('AdState', JSON.stringify(this.state.currentview));
      } );

    // turn on the listener for the contact list
      this.testListener();
    // if the props are set up right run the admin listener
    if(this.props.creds !== null){
      if(this.props.creds.admin === true ){
        this.adminListener();
      }
    }
  }

  componentWillUnmount(){
    //use the removal of the EventListener to create and set the local storage item
    window.removeEventListener("beforeunload", () => {
      localStorage.setItem('AdState', JSON.stringify(this.state.currentview));
    })
  }

 render() {
   const {language} = this.props;
   const  {engAdmin, frAdmin} = this.state;
   return(
     <div className='panel'>
<h1>{language === 'english'? engAdmin.panel:frAdmin.panel}</h1>
{this.navAdmin()}

{this.viewControl(this.state.currentview)}

</div>

   )
 }
}
