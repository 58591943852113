import React, { Component } from 'react';
import './index.css';
import Img404 from '../../resources/VFD_404.png';
import Img404mobile from '../../resources/VFD_404_mobile.png';
import MediaQueries from 'react-responsive';

export default class Unknown extends Component {

  constructor(props){
    super(props);
    this.state={

        }
      }

  componentDidMount(){
      window.scrollTo(0, 0)
  }
 render() {

   return(
    <div className='unknown' >
     <h1> {this.props.error.error} </h1>

     <MediaQueries maxWidth ={768}>
       {(matches) => {
         if (matches){
           return <img className='lostimg' src={Img404mobile} alt="404 mobile" />
         }else{
            return <img className='lostimg' src={Img404} alt="404 desktop"/>
         }
       }}

     </MediaQueries>
   </div>

   )
 }
}
